<template>
	<div class="draggable-component">
		<div class="draggable-category">
			<div class="draggable-header">
				<h2>{{title}}</h2>
				<div class="header-buttons">
					<i
						v-show="isSaving"
						class="fas fa-spinner fa-spin"
					></i>
					<CButton
						@click="add"
						v-c-tooltip.hover.click="'Aggiungi'"
					>
						<i class="fas fa-plus"></i>
					</CButton>
					<CButton
						:disabled="disableActions"
						@click="$emit('save')"
						v-c-tooltip.hover.click="'Salva'"
					>
						<i class="fas fa-save"></i>
					</CButton>
					<CButton
						:disabled="disableActions"
						@click="reset"
						v-c-tooltip.hover.click="'Ripristina'"
					>
						<i class="fas fa-redo"></i>
					</CButton>
				</div>
			</div>
			<CAlert
				color="sdRed"
				v-if="params.dirty"
			>
				Salvare le modifiche per impostare il nuovo ordine.
			</CAlert>
			<template v-if="params.items.length > 0">
				<Container @drop="onDrop($event)">
					<Draggable
						v-for="item in params.items"
						:key="item.id"
					>
						<custom-card
							:params="item"
							@clicked="clicked"
							@editItem="editItem(item)"
							@deleteItem="deleteItem(item)"
							@cloneItem="cloneItem(item)"
							:canClone="canClone"
							:class="{ active: active === item.id }"
						></custom-card>
					</Draggable>
				</Container>
			</template>
			<template v-else>
				Non ci sono elementi associati.
			</template>
		</div>
	</div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd';
import { applyDrag } from "../../utilities/drag-helpers";

export default {
	name: 'DraggableCategory',
	components: { Container, Draggable },
	created() {
		this._params = Object.assign({}, this.params);
	},
	data() {
		return {
			categories: [],
			phases: [],
			exercises: [],
		}
	},
	props: {
		params: {
			items: {
				type: Array,
				default() {
					return []
				}
			},
			dirty: {
				type: Boolean,
				default: false
			}
		},
		title: {
			type: String,
			default: ''
		},
		active: {
			type: String,
			default: ''
		},
		isSaving: {
			type: Boolean,
			default: false
		},
		canClone: {
			type: Boolean,
			default: false
		},
	},
	computed: {
		disableActions() {
			return this.params && (!this.params.dirty || this.isSaving) ? true : false;
		},
	},
	methods: {
		onDrop(dropResult) {
			if (dropResult.addedIndex !== dropResult.removedIndex) {
				this.$emit('onDrop', { items: applyDrag(this.params.items, dropResult), dirty: true });
			}
		},
		add() {
			this.$emit('add');
		},
		reset() {
			this.$emit('reset', this._params);
		},
		clicked(item) {
			this.$emit('clicked', item);
		},
		editItem(item) {
			this.$emit('editItem', item);
		},
		deleteItem(item) {
			this.$emit('deleteItem', item);
		},
		cloneItem(item) {
			this.$emit('cloneItem', item);
		},
	},
	emits: {
		onDrop: {
			type: Array,
		},
		save: {
			type: undefined
		},
		clicked: {
			type: Object
		},
		reset: {
			type: Array
		},
		editItem: {
			type: Object
		},
		deleteItem: {
			type: Object
		},
		addtem: {
			type: Function
		},
		cloneItem: {
			type: Function
		}
	},
}
</script>

<style scoped lang="scss">
i {
	font-size: 1.25rem;
}
</style>
<template>
	<div class="allenamenti">
		<draggable-category
			v-if="categories"
			:params="categories"
			:active="activeCategory"
			@onDrop="onCategoryDrop"
			@save="onCategoriesSave"
			@editItem="onCategoryEdit"
			@deleteItem="onCategoryDelete"
			@clicked="onCategoriesClicked"
			@reset="onCategoriesReset"
			@add="onCategoriesAdd"
			@cloneItem="onCategoriesClone"
			title="Categorie"
			:canClone="true"
			:isSaving="isSavingCategories"
		></draggable-category>
		<draggable-category
			v-if="phases && activeCategory"
			:params="phases"
			:active="activePhase"
			@onDrop="onPhasesCategoryDrop"
			@save="onPhasesSave"
			@editItem="onPhaseEdit"
			@deleteItem="onPhaseCategoryDelete"
			@clicked="onPhasesClicked"
			@reset="onPhasesCategoryReset"
			@add="onPhasesAdd"
			title="Fasi"
			:isSaving="isSavingPhases"
		></draggable-category>
		<draggable-category
			v-if="exercises && activePhase"
			:params="exercises"
			@onDrop="onExercisesDrop"
			@save="onExercisesSave"
			@editItem="onExerciseEdit"
			@deleteItem="onExerciseDelete"
			@reset="onExercisesReset"
			@add="onExercisesAdd"
			title="Esercizi"
			:isSaving="isSavingExercises"
		></draggable-category>
	</div>
</template>

<script>
import DraggableCategory from '../components/DraggableCategory.vue'
import { getAllenamentiModalOptions, getDeleteCategoryModalOptions, getDeletePhaseModalOptions, getDeleteExerciseModalOptions, pushToast, deleteModalOptions } from "../../utilities/utilities";

export default {
	name: "Allenamenti",
	created() {
		this.loadCategories();
	},
	components: {
		DraggableCategory,
	},
	beforeDestroy() {
		this.$store.commit('setLastPhaseRank', undefined);
		this.$store.commit('setLastExerciseRank', undefined);
		this.$store.commit('setActiveCategory', undefined);
		this.$store.commit('setActivePhase', undefined);
	},
	data() {
		return {
			activeCategory: undefined,
			activePhase: undefined,
			activeExercise: undefined,
			isSavingCategories: false,
			isSavingPhases: false,
			isSavingExercises: false,
		}
	},
	computed: {
		shouldShowAlert() {
			let result = false;
			if ((this.exercises && this.exercises.dirty) || (this.phases && this.phases.dirty) || (this.categories && this.categories.dirty)) {
				result = true;
			}
			return result;
		},
		untouchedExercises() {
			return !this.exercises || (this.exercises && !this.exercises.dirty);
		},
		categories: {
			get() {
				return this.$store.state.categories;
			},
			set(data) {
				this.$store.commit('setCategories', data);
			}
		},
		phases: {
			get() {
				return this.$store.state.phases;
			},
			set(data) {
				this.$store.commit('setPhases', data);
			}
		},
		exercises: {
			get() {
				return this.$store.state.exercises;
			},
			set(data) {
				this.$store.commit('setExercises', data);
			}
		},
	},
	methods: {
		loadCategories() {
			this.isSavingCategories = true;
			this.$store.dispatch("getCategories").then(data => {
				this.categories = this.onModelingDataCategories(data);
				this.isSavingCategories = false;
				const dataLength = data && data.length;
				if (dataLength > 0) {
					this.$store.commit("setLastCategoryRank", data[dataLength - 1].rank);
				}
			});
		},
		loadPhasesByCategory(id) {
			this.isSavingPhases = true;
			this.$store.dispatch("getPhases", id).then(data => {
				this.phases = this.onModelingDataPhases(data);
				const dataLength = data && data.length;
				this.$store.commit("setLastPhaseRank", dataLength > 0 ? data[dataLength - 1].rank : 0);
				this.isSavingPhases = false;
			});
		},
		loadExercisesByCategoryPhase(id) {
			this.isSavingExercises = true;
			this.$store.dispatch("getExercises", { phaseId: id, categoryId: this.activeCategory }).then(data => {
				this.exercises = this.onModelingDataExercises(data);
				this.isSavingExercises = false;
				const dataLength = data && data.length;
				if (dataLength > 0) {
					this.$store.commit("setLastExerciseRank", data[dataLength - 1].rank);
				}
			});
		},
		onCategoriesAdd() {
			this.$store.commit('setupModalOptions', {
				addContentClasses: 'modal-footer-hidden', title: 'Nuova categoria', component: "Categoria", footerComponent: 'ModalFooterComponent',
				footerParams: {
					cancelButtonTitle: 'Annulla',
					confirmButtonTitle: 'Conferma'
				},
				params: { isNew: true }
			});
		},
		onCategoriesClone(item) {
			this.$store.commit('setupModalOptions', {
				title: 'Clonazione in corso', body: "Vuoi clonare questa categoria e tutte le sottocategorie associate?", footerComponent: 'ModalFooterComponent',
				footerParams: {
					cancelButtonTitle: 'Annulla',
					confirmButtonTitle: 'Conferma'
				},
				callback: (action) => {
					if (action === 1) {
						this.$store.commit('loadState', true);
						this.$store.dispatch('cloneCategories', { id: item.id, rank: (this.categories.items[this.categories.items.length - 1].rank) + 1 }).then(x => {
							this.loadCategories();
							this.pushToast({ title: 'Clonazione', body: 'Clonazione andata a buon fine', color: 'success' });
						});
						this.$store.commit('loadState', false);
					}
					this.deleteModalOptions();
				}
			});
		},
		onPhasesAdd() {
			this.$store.commit('setupModalOptions', {
				addContentClasses: 'modal-footer-hidden', title: 'Nuova fase', component: "Fase", footerComponent: 'ModalFooterComponent',
				footerParams: {
					cancelButtonTitle: 'Annulla',
					confirmButtonTitle: 'Conferma'
				},
				params: { categoryId: this.activeCategory, isNew: true }
			});
		},
		onExercisesAdd() {
			this.$store.commit('setupModalOptions', {
				addContentClasses: 'modal-footer-hidden', title: 'Nuovo esercizio', component: "Esercizio", footerComponent: 'ModalFooterComponent',
				footerParams: {
					cancelButtonTitle: 'Annulla',
					confirmButtonTitle: 'Conferma'
				},
				params: { categoryId: this.activeCategory, phaseId: this.activePhase, isNew: true }
			});
		},
		onCategoryEdit(item) {
			this.$store.commit('setActiveCategory', item.id);
			this.$store.commit('setupModalOptions', { addContentClasses: 'modal-footer-hidden', title: 'Modifica categoria', component: "Categoria" });
		},
		onPhaseEdit(item) {
			this.$store.commit('setActivePhase', item.id);
			this.$store.commit('setupModalOptions', { addContentClasses: 'modal-footer-hidden', title: 'Modifica fase', component: "Fase" });
		},
		onExerciseEdit(item) {
			this.$store.commit('setActiveExercise', item.id);
			this.$store.commit('setupModalOptions', { addContentClasses: 'modal-footer-hidden', title: 'Modifica esercizio', component: "Esercizio" });
		},
		onCategoryDelete(data) {
			this.$store.commit('setupModalOptions', getDeleteCategoryModalOptions(data.name, (action) => {
				if (action === 1) {
					const id = data.id;
					const index = this.categories.items.findIndex(x => x.id === id);
					this.$store.commit('loadState', true);
					this.$store.dispatch('deleteCategory', id).then(x => {
						this.$store.commit('removeCategory', index);
						this.pushToast({ title: 'Cancellazione', body: 'Categoria cancellata', color: 'success' });
						if (id === this.activeCategory) {
							this.phases = undefined;
							this.exercises = undefined;
							this.$store.commit('setLastPhaseRank', undefined);
							this.$store.commit('setLastExerciseRank', undefined);
							this.$store.commit('setActiveCategory', undefined);
							this.$store.commit('setActivePhase', undefined);
						}
					});
					this.$store.commit('loadState', false);
				}
			}));
		},
		onPhaseCategoryDelete(data) {
			this.$store.commit('setupModalOptions', getDeletePhaseModalOptions(data.name, (action) => {
				if (action === 1) {
					const id = data.id;
					const index = this.phases.items.findIndex(x => x.id === id);
					this.$store.commit('loadState', true);
					this.$store.dispatch('deletePhase', { phaseId: id, categoryId: this.activeCategory }).then(() => {
						this.$store.commit('removePhase', index);
						this.$store.commit('updateActiveCategoryCount', { id: this.activeCategory, action: -1 });
						this.pushToast({ title: 'Cancellazione', body: 'Fase cancellata', color: 'success' });
						if (id === this.activePhase) {
							this.exercises = undefined;
							this.$store.commit('setLastPhaseRank', undefined);
							this.$store.commit('setLastExerciseRank', undefined);
							this.$store.commit('setActivePhase', undefined);
						}
					});
					this.$store.commit('loadState', false);
				}
			}));
		},
		onExerciseDelete(data) {
			this.$store.commit('setupModalOptions', getDeleteExerciseModalOptions(data.name, (action) => {
				if (action === 1) {
					const id = data.id;
					const index = this.exercises.items.findIndex(x => x.id === id);
					this.$store.commit('loadState', true);
					this.$store.dispatch('deleteExercise', { phaseId: this.activePhase, categoryId: this.activeCategory, exerciseId: id }).then(x => {
						this.$store.commit('removeExercise', index);
						this.$store.commit('updateActivePhaseCount', { id: this.activePhase, action: -1 });
						this.pushToast({ title: 'Cancellazione', body: 'Esercizio cancellato', color: 'success' });
						this.$store.commit('setLastExerciseRank', undefined);
					});
					this.$store.commit('loadState', false);
				}
			}));
		},
		onCategoriesReset() {
			this.activeExercise = undefined;
			this.exercises = undefined;
			this.activePhase = undefined;
			this.phases = undefined;
			this.activeCategory = undefined;
			this.loadCategories();
		},
		onPhasesCategoryReset() {
			this.reloadByCategoryChange(this.activeCategory);
		},
		onExercisesReset() {
			this.reloadByPhaseChange(this.activePhase);
		},
		onCategoriesSave() {
			if (this.categories.dirty) {
				this.isSavingCategories = true;
				this.$store.dispatch("saveCategories", this.rankReorder(this.categories.items)).then(data => {
					this.isSavingCategories = false;
					this.pushToast({ title: 'Aggiornamento', body: 'Categorie riordinate con successo', color: 'success' });
				});
				this.$set(this.categories, 'dirty', false);
			}
		},
		onExercisesSave() {
			if (this.exercises.dirty) {
				this.isSavingExercises = true;
				this.$store.dispatch("saveExercises", this.rankReorder(this.exercises.items)).then(data => {
					this.isSavingExercises = false;
					this.pushToast({ title: 'Aggiornamento', body: 'Esercizi riordinati con successo', color: 'success' });
				});
				this.$set(this.exercises, 'dirty', false);
			}
		},
		onPhasesSave() {
			if (this.phases.dirty) {
				this.isSavingPhases = true;
				this.$store.dispatch("savePhases", this.rankReorder(this.phases.items)).then(data => {
					this.isSavingPhases = false;
					this.pushToast({ title: 'Aggiornamento', body: 'Fasi riordinate con successo', color: 'success' });
				});
				this.$set(this.phases, 'dirty', false);
			}
		},
		rankReorder(items) {
			return items.map((x, index) => ({ id: x.id, rank: index + 1 }));
		},
		onCategoriesClicked(item) {
			const id = item.id;
			this.$store.commit('setActiveCategory', id);
			if (!this.activeCategory || this.activeCategory !== id) {
				if (!this.phases || (this.phases && !this.phases.dirty) && (this.untouchedExercises)) {
					this.activeCategory = id;
					this.reloadByCategoryChange(this.activeCategory);
				} else {
					this.showSaveAlert(id, 'activeCategory');
				}
			}
		},
		onModelingDataCategories(data) {
			return Object.assign({}, {
				items: data,
				dirty: false
			});
		},
		onModelingDataExercises(data) {
			return Object.assign({}, {
				items: data,
				dirty: false
			});
		},
		onModelingDataPhases(data) {
			return Object.assign({}, {
				items: data,
				dirty: false
			});
		},
		onPhasesClicked(item) {
			const id = item.id;
			this.$store.commit('setActivePhase', id);
			if (!this.activePhase || this.activePhase !== id) {
				if (this.untouchedExercises) {
					this.activePhase = id;
					this.reloadByPhaseChange(this.activePhase);
				} else {
					this.showSaveAlert(id, 'activePhase');
				}
			}
		},
		reloadByPhaseChange(id) {
			this.activeExercise = undefined;
			this.loadExercisesByCategoryPhase(id);
		},
		reloadByCategoryChange(id) {
			this.activeExercise = undefined;
			this.exercises = undefined;
			this.activePhase = undefined;
			this.loadPhasesByCategory(id);
		},
		showSaveAlert(id, prop) {
			this.$store.commit('setupModalOptions', getAllenamentiModalOptions(this.getTitleContext(prop), action => {
				if (action === 1) {
					switch (prop) {
						case 'activePhase':
							this.activePhase = id;
							this.$set(this.phases, 'dirty', false);
							this.reloadByPhaseChange(id);
							break;
						case 'activeCategory':
							this.activeCategory = id;
							this.$set(this.categories, 'dirty', false);
							this.reloadByCategoryChange(id);
							break;
					}
				}
			}));
		},
		onCategoryDrop(data) {
			this.categories = Object.assign({}, data);
		},
		onPhasesCategoryDrop(data) {
			this.phases = Object.assign({}, data);
		},
		onExercisesDrop(data) {
			this.exercises = Object.assign({}, data);
		},
		getTitleContext(prop) {
			let result = 'gli esercizi';
			if (prop === 'activeCategory') {
				if (this.phases.dirty && this.exercises && this.exercises.dirty) {
					result = 'le fasi e gli esercizi';
				} else if (this.phases.dirty) {
					result = 'le fasi';
				}
			}
			return result;
		},
		pushToast,
		deleteModalOptions
	}
}
</script>